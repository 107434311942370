<template>
  <div class="container app-content pt-4">
    <Error v-if="error" :error="error" />
    <Loading v-else-if="isLoading" />
    <template v-else>
      <!-- Page Header -->
      <PageHeader :title="`${user.first} ${user.last}`" />

      <MyProfileNavigation v-if="!isLoading" :user="user" />

      <dl class="row mt-5">
        <div class="col-md-4">
          <dl class="row">
            <dt class="col-sm-4">First Name</dt>
            <dd class="col-sm-8">{{ user.first }}</dd>

            <dt class="col-sm-4">Last Name</dt>
            <dd class="col-sm-8">{{ user.last }}</dd>

            <dt class="col-sm-4">Organization</dt>
            <dd class="col-sm-8">{{ user.org }}</dd>

            <dt class="col-sm-4">Email</dt>
            <dd class="col-sm-8">{{ user.email ? user.email : '--' }}</dd>

            <dt class="col-sm-4">Phone</dt>
            <dd class="col-sm-8">{{ user.phone ? user.phone : '--' }}</dd>
          </dl>
        </div>

        <div class="col-md-3">
          <dl class="row">
            <dt class="col-sm-4">Location</dt>
            <dd class="col-sm-8">
              {{ user.city }}, {{ user.state }}, {{ user.zip }}, {{ user.country }}
            </dd>

            <dt class="col-sm-4">Language</dt>
            <dd class="col-sm-8">{{ user.lang }}</dd>

            <dt class="col-sm-4">Timezone</dt>
            <dd class="col-sm-8">{{ user.timezone }}</dd>

            <dt class="col-sm-4">Currency</dt>
            <dd class="col-sm-8">{{ user.currency }}</dd>
          </dl>
        </div>

        <div class="col-md-5">
          <dl class="row">
            <dt class="col-sm-4">Last Login</dt>
            <dd class="col-sm-8">{{ fullDateTime(user.last_login_at) }}</dd>

            <dt class="col-sm-4">Last Activity</dt>
            <dd class="col-sm-8">{{ fullDateTime(user.last_activity_at) }}</dd>

            <dt class="col-sm-4">Last Updated</dt>
            <dd class="col-sm-8">{{ fullDateTime(user.updated_at) }}</dd>

            <dt class="col-sm-4">User Created</dt>
            <dd class="col-sm-8">{{ fullDateTime(user.created_at) }}</dd>

            <dt class="col-sm-4">Role(s)</dt>
            <dd class="col-sm-8">{{ getRoles(user.roles).join(', ') }}</dd>
          </dl>
        </div>
      </dl>
    </template>

    <Modal id="modal-my-profile-edit">
      <template v-slot:title>Edit My Profile</template>

      <template v-slot:body>
        <UserForm v-if="!isLoading" modal="#modal-my-profile-edit" :initial="user" />
      </template>
    </Modal>

    <Modal id="modal-my-profile-permissions">
      <template v-slot:title>Edit Permissions</template>

      <template v-slot:body>
        <UserPermissionsForm
          v-if="!isLoading"
          modal="#modal-my-profile-permissions"
          :user="user"
          :defined-roles="definedRoles"
        />
      </template>
    </Modal>
  </div>
</template>

<script>
import Error from '../components/Error';
import hasTimeDate from '../mixins/hasTimeDate';
import hasUser from '../mixins/hasUser';
import Loading from '../components/Loading';
import Modal from '../components/Modal';
import MyProfileNavigation from '../components/my-profile/MyProfileNavigation';
import PageHeader from '../components/PageHeader';
import UserForm from '../components/forms/UserForm';
import UserPermissionsForm from '../components/forms/UserPermissionsForm';

export default {
  name: 'MyProfile',
  mixins: [hasTimeDate, hasUser],
  components: {
    Error,
    Loading,
    Modal,
    MyProfileNavigation,
    PageHeader,
    UserForm,
    UserPermissionsForm,
  },
  data() {
    return {
      error: null,
      isLoading: true,
    };
  },
  computed: {
    user() {
      return this.$store.getters['users/getActiveUser'];
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      // #TODO lock the page while loading
      this.error = null;
      this.isLoading = true;

      this.$store
        .dispatch('users/getMe')
        .catch((error) => {
          this.error = this.$errorProcessor(error);
        })
        .then(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss"></style>
